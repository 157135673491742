import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import footStyles from './footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    authorURL

                }
            }
        }
    `)
    return (
        <div className={footStyles.footer}>
            <p> © 2020 Deal's Appliance Service | Site created by <a href={data.site.siteMetadata.authorURL} target="_blank" rel="noreferrer">{data.site.siteMetadata.author}</a></p>
        </div>
    )
}

export default Footer