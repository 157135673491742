import React from 'react'

import Nav from './Navigation.js'
import Footer from './Footer.js'

import '../styles/index.scss'

const Layout = (props) => {
    return (
        <div>
            <Nav />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout