import React from 'react'
import navStyles from './nav.module.scss'

import { Link, graphql, useStaticQuery } from 'gatsby'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Nav = () => {
    const data = useStaticQuery(graphql`
        query {
           site {
               siteMetadata {
                   title
               }
           } 
        }
    `)


    return (
        <nav className={navStyles.nav}>
            <div>
                <div>
                <AnchorLink to="/#home" className={navStyles.logo}>
                    {data.site.siteMetadata.title}
                </AnchorLink>
                    <ul>
                        <AnchorLink to="/#service"><li>Service</li></AnchorLink>
                        <AnchorLink to="/#sales"><li>Sales</li></AnchorLink>
                        <AnchorLink to="/#parts"><li>Parts</li></AnchorLink>
                        <AnchorLink to="/#contact"><li>Contact</li></AnchorLink>
                    </ul>
                </div>
                <Link to="/request" className={navStyles.btn}>Request Service</Link>
            </div>
        </nav>
    )
}

export default Nav